import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const SparenImmobilienKauf = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Wie du beim Immobilienkauf sparen kannst" showCalc={false} />
            <Article>
                <h2>10 Tipps, die dir helfen, beim Immobilienkauf zu sparen</h2>
                <p>
                    Wenn es um den Kauf einer Immobilie geht, gilt die Devise: Wissen istMacht – und in dem Fall auch
                    Geld. Erfahre hier, wie du beim und auch nach dem Immobilienkauf Geld sparen kannst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Probiere unseren Kreditrechner, um dir einen ersten Überblick zu verschaffen!
                    </a>
                </p>
                <hr />

                <h2>1. Finde einen erfahrenen Immobilienmakler oder eine erfahrene Maklerin</h2>
                <p>
                    Die Inanspruchnahme eines zuverlässigen und erfahrenen Immobilienmaklers, der sich in der Gegend gut
                    auskennt, kann dir Zeit und Geld sparen. Das gilt besonders für Erstkäufer, da ein guter
                    Immobilienmakler, eine gute Immobilienmaklerin dir helfen kann, dich im gesamten Prozess
                    zurechtzufinden und dabei herauszufinden, wie du Geld sparen kannst. Empfehlungen für gute
                    Makler*innen erhältst du vielleicht von Verwandten, Freunden, Arbeitskolleg*innen und Anwohner*innen
                    oder über Online-Immobilienportale.
                </p>
                <hr />

                <h2>2. Spare so viel möglich für die Anzahlung</h2>
                <p>
                    Es ist nicht immer einfach, die empfohlene Anzahlung von 20 % zu leisten, aber auf lange Sicht
                    kannst du damit Geld sparen. Eine hohe Anzahlung kann nicht nur deine monatliche Hypothekenzahlung
                    senken, sondern auch dazu beitragen, dass du bei der Kreditwahl bessere Konditionen erhältst.
                </p>
                <hr />

                <h2>3. Verbessere vor dem Kauf deine Kreditwürdigkeit</h2>
                <p>
                    Deine Kreditwürdigkeit wirkt sich direkt auf die von dir zu zahlenden Zinssätze
                    undKreditgebergebühren aus. Wenn du dir die Zeit nimmst, deine Kreditwürdigkeit vor dem Kauf zu
                    verbessern, kannst du deine monatlichen Hypothekenzahlungen senken und dadurch viel Geld sparen. Je
                    höher die Kreditwürdigkeit ist, desto wahrscheinlicher ist es, dass du dich für einen niedrigeren
                    Zinssatzqualifizierst.
                </p>
                <hr />

                <h2>4. Investiere in eine gründliche Immobilieninspektion</h2>
                <p>
                    Eine genaue Inspektion der Immobilie durch Experten kann dich zwar ein paar hundert Euro kosten,
                    aber sie kann dir auch Tausende sparen, wenn etwas mit dem Haus oder der Wohnung nicht stimmen
                    sollte. Wenn es ein Problem gibt, kannst du mit de*r Verkäufer*in verhandeln, um den Verkaufspreis
                    zu senken.
                </p>
                <hr />

                <h2>5. Spare nicht bei der Suche nach dem richtigen Kreditinstitut</h2>
                <p>
                    Spreche nicht gleich mit dem erstbesten Kreditgeber, der dir ein Darlehen anbietet, sondern mit
                    mehreren Kreditinstituten. So stellst du sicher, dass du wirklich den besten{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    für deine Bedürfnisse bekommst. Verschiedene Kreditgeber bieten unterschiedliche Zinssätze
                    undDarlehensoptionen an, und es ist wichtig, dass du dich vor der Auswahl eines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    über alle verfügbaren Angeboteinformierst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>6. Finde zuerst heraus, wie viel du dir leisten kannst</h2>
                <p>
                    Bevor du ein Haus oder eine Wohnung kaufst, ist es wichtig zu wissen, wie viel du dir leisten
                    kannst. So kannst du sicherstellen, dass du auch eine Preisspanne und eine Hypothek mit einer
                    monatlichen Rate wählst, die du dir leisten kannst. Eine Möglichkeit, die richtige monatliche
                    Hypothekenzahlung zu ermitteln, ist die Verwendung eines{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechners
                    </Link>
                    . Damit erhältst du eine gute Schätzung dessen, was du dir auf der Grundlage deines Einkommens,
                    deiner monatlichen Ausgaben und des voraussichtlichen Hypothekenzinses leisten kannst.
                </p>
                <hr />

                <h2>7. Ein Haus zu kaufen bedeutet, einen Vertrag zu schließen</h2>
                <p>
                    Wenn du die Papiere für den Kauf eines Hauses unterschreibst, gehst du einen langfristigen Vertrag
                    ein, der nicht gebrochen werden kann. Bevor du jedoch unterschreibst, gibt es immer
                    Verhandlungsspielraum. Wenn du mit einem Teil des Vertrags nicht einverstanden bist, verhandle mit
                    dem Verkäufer, bis du dich mit den Bedingungen einverstanden erklären kannst. Ziehe auch in
                    Erwägung, eine Klausel einzufügen, wonach der Immobilienwert auf der Grundlage der Schätzung des
                    Kreditgebers dem Verkaufspreis entsprechen oder diesen übersteigen muss, damit du nicht eine höhere
                    Anzahlung leisten musst als erwartet und dir fiese Überraschungenersparst.
                </p>
                <hr />

                <h2>8. Zögere nicht, eine Anwältin oder einen Anwalt zu beauftragen</h2>
                <p>
                    Wenn du einen Anwaltbeauftragst, bevor du einen Vertrag unterschreibst, kannst du sicherstellen,
                    dass du nichts unterschreibst, womit du nicht einverstanden bist. Wähle einen erfahrenen Anwalt oder
                    eine Anwältin, der oder die im Immobilienbereich tätig ist, und lasse deinen Vertrag vor der
                    Unterzeichnung gründlich überprüfen.
                </p>
                <hr />

                <h2>9. Mit der Lage einer Immobilie sparen</h2>
                <p>
                    Die Lage deiner Immobilie entscheidet nicht nur über die Lebensqualität, sondern auch über den
                    Preis. Bedenke, dass Wohnungen in der Stadt meist um einiges teurer sind, als Immobilien auf dem
                    Land und frage dich, was dir lieber ist. Bedenke auch Faktoren wie Infrastruktur, Verkehrs- und
                    Straßenanbindungen, Arbeitsmarkt und Bildungsangebot in der Nähe. Alle diese Faktoren beeinflussen
                    den Preis. Wie viel Wert legst du auf eine gute Infrastruktur und welche Faktoren sind dir dabei am
                    wichtigsten? Sind es die Bildungsangebote und die Natur oder eher eine große Auswahl an Kultur- und
                    Freizeitangeboten sowie Einkaufsmöglichkeiten? Verzichtest du auf gewisse Faktoren, die dir weniger
                    wichtig sind, kannst du einiges sparen. Informiere dich auch gut über Projekte, die in der Umgebung
                    deiner Immobilie geplant sind und eine Steigerung des Immobilienwertes bewirken könnten (z.B.
                    Erweiterung des U-Bahn-Netzes in Wien oder Eröffnungen neuer Einkaufsmöglichkeiten).
                </p>
                <hr />

                <h2>10. Mithilfe von Wohnbauförderung oder Bausparvertrag sparen</h2>
                <p>
                    Die Wohnbauförderung ist von Bundesland zu Bundesland unterschiedlich geregelt und umfasst sowohl
                    Neubau, Umbau als auch Renovierungsarbeiten. Doch auch der Kauf einer gebrauchten Immobilie wie
                    einer Wohnung kann von deinem Bundesland gefördert werden.
                </p>
                <p>
                    Auch ein Bausparvertrag kann sinnvoll sein, um Geld zu sparen. In Österreich ist Bausparen ziemlich
                    beliebt – fast 60 % der Österreicher*innen haben einen Bausparvertrag. Dieser hat einige Vorteile:
                    Neben den Sparzinsen gibt es jährlich eine staatliche Prämie zwischen 1,5 und 4 %, die jedes Jahr
                    neu berechnet wird. Es werden bis zu 100&euro; monatlich bzw. 1.200 Euro im Jahr gefördert. In
                    Österreich kannst du zwischen vier verschiedenen Bausparkassen wählen:
                </p>
                <ul>
                    <li>start:bausparkasse AG</li>
                    <li>Raiffeisen Bausparkasse Ges.m.b.H.</li>
                    <li>Bausparkasse Wüstenrot AG</li>
                    <li>s Bausparkasse AG</li>
                </ul>
                <p>
                    <strong>Tipp:</strong> Informiere dich auch über spezielle Projekte zur Förderung von Eigentum in
                    deinem Ort. Die Stadt Wien bietet beispielsweise geförderte Wohnungen mit Eigentumsoption an.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Erstgespräch!
                    </a>
                </p>
                <p>
                    Je mehr du vor dem Kauf eines Hauses weißt, desto mehr kannst du sowohl vor als auch nach Abschluss
                    des Vertrages sparen. Nimm dir Zeit, um dich mit den verschiedenen Faktoren vertraut zu machen, die
                    zu hohen Kosten führen können, und mit den Maßnahmen, die du ergreifen kannst, um diese zu
                    vermeiden. Wenn du mehr über den Prozess des Hauskaufs erfahren möchtest, sprich mit unseren
                    Berater*innen oder verschaffe dir mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    einen ersten Überblick.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"sparenImmobilienKauf"}></BreadcrumbList>
            <ArticleStructuredData page={"sparenImmobilienKauf"} heading={"Wie du beim Immobilienkauf sparen kannst"} />
        </Layout>
    );
};

export default SparenImmobilienKauf;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.sparen-immobilien-kauf", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
